import React from "react";

import Included from "./Included";
import ServiceText from "./ServiceText";

// image imports
import windowCleaningHeader from "../../../media/Window Cleaning Header.png";

const WindowCleaning = () => {
  return (
    <>
      <div className="servicesHeader">
        <img
          src={windowCleaningHeader}
          alt="Window Cleaning title over an image of a house"
        />
      </div>

      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Window Cleaning</h1>
          <div className="windowContent">
            <h2>Exterior Window Cleaning</h2>
            <p>
              All of our exterior window cleaning is done using the latest in
              window cleaning technology. Our four stage water-purification unit
              and water fed poles ensure that your windows get the best possible
              clean, all while having our staff remain safely on the ground. Not
              only will we make your windows sparkle, but will also wash the
              frames and sills at the same time.
            </p>
          </div>

          <div className="windowContent">
            <h2>Interior Window Cleaning</h2>
            <p>
              Our interior cleaning is all done by hand using washers and
              squeegees. Along with the glass, we also wipe down all of your
              frames and sills to make the entire window shine. Whether we're
              working on the outside or the inside, we always work with the
              greatest of care. Our professionally trained technicians treat
              your home as if it were their own, always using shoe booties and
              drop cloths to ensure we leave your home even more beautiful then
              when we arrived.
            </p>
          </div>

          <div className="windowContent">
            <h2>Screen Washing</h2>
            <p>
              All screen washing includes the removal and replacement of the
              screens. We wash all screens by hand, as hand washing tends to be
              the best clean, often getting dirt that most other methods leave
              behind. Screens are typically on the inside of the home and can be
              added to an interior cleaning. We're also happy to include them
              with an exterior window cleaning, as many of our exterior
              customers have us do, just let us know in advance of your job!
            </p>
          </div>

          <ServiceText />
          <Included />
        </div>
      </div>
    </>
  );
};

export default WindowCleaning;
