import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";

import Services from "./components/services/Services";
import WindowCleaning from "./components/services/WindowCleaning";
import SidingWashing from "./components/services/SidingWashing";
import Eavestrough from "./components/services/Eavestrough";
import PowerWashing from "./components/services/PowerWashing";
import Mosquito from "./components/services/Mosquito";

import About from "./components/about/About";
import CustomerReviews from "./components/about/CustomerReviews";
import WhyGreen from "./components/about/WhyGreen";
import AlwaysGreen from "./components/about/AlwaysGreen";
import Guarantee from "./components/about/Guarantee";
import Vip from "./components/about/Vip";
import JobOpportunities from "./components/about/JobOpportunities";

import Payment from "./components/Payment";
import Contact from "./components/Contact";
import FreeQuote from "./components/FreeQuote";

import Header from "./components/Header";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Footer from "./components/Navigations/Footer";
import ThankYou from "./components/ThankYou";
import ThankYouPayment from "./components/ThankYouPayment";
import "../styles/sass/style.css";
import ScrollToTop from "./components/ScrollToTop";

function App() {
  return (
    <Router>
      <div className="app">
        <ScrollToTop>
          <a className="skip-main" href="#main">
            Skip to main content
          </a>
          <Header />
          <main id="main">
            <Routes>
              <Route exact path="" element={<Home />} />
              {/* Our Services */}
              <Route exact path="/ourServices" element={<Services />} />
              <Route
                exact
                path="/windowCleaning"
                element={<WindowCleaning />}
              />
              <Route exact path="/sidingWashing" element={<SidingWashing />} />
              <Route exact path="/eavestrough" element={<Eavestrough />} />
              <Route exact path="/powerWashing" element={<PowerWashing />} />
              <Route exact path="/mosquitoControl" element={<Mosquito />} />
              {/* About Us */}
              <Route exact path="/aboutUs" element={<About />} />
              <Route
                exact
                path="/customerReviews"
                element={<CustomerReviews />}
              />
              <Route exact path="/whyGreen" element={<WhyGreen />} />
              <Route exact path="/alwaysGreen" element={<AlwaysGreen />} />
              <Route exact path="/ourVipRewardsProgram" element={<Vip />} />
              <Route exact path="/ourGuarantee" element={<Guarantee />} />
              <Route
                exact
                path="/jobOpportunities"
                element={<JobOpportunities />}
              />
              <Route exact path="/freeQuote" element={<FreeQuote />} />
              <Route exact path="/contactUs" element={<Contact />} />
              <Route exact path="/payment" element={<Payment />} />
              <Route exact path="/thankyou" element={<ThankYou />} />
              <Route
                exact
                path="/thankYouPayment"
                element={<ThankYouPayment />}
              />
              <Route exact path="/privacyPolicy" element={<Privacy />} />
              <Route exact path="/terms" element={<Terms />} />
            </Routes>
          </main>
          <Footer />
        </ScrollToTop>
      </div>
    </Router>
  );
}

export default App;
