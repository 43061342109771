import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import ReusableContact from "./ReusableContact";
import emailjs from "@emailjs/browser";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Required";
  } else if (values.first_name.length > 15) {
    errors.first_name = "Must be 15 characters or less";
  }

  if (!values.last_name) {
    errors.last_name = "Required";
  } else if (values.last_name.length > 20) {
    errors.last_name = "Must be 20 characters or less";
  }

  if (!values.address) {
    errors.address = "Required";
  } else if (values.address.length < 1) {
    errors.address = "Must include address";
  }

  if (!values.city) {
    errors.city = "Required";
  } else if (values.city.length < 1) {
    errors.city = "Must include city name";
  }
  if (!values.number) {
    errors.number = "Required";
  } else if (!/^\d{10}$|^(\d{3}-){2}\d{4}$/.test(values.number)) {
    errors.number = "Invalid phone number";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (values.service_checked.length < 1) {
    errors.service_checked = "Please select a service option";
  }
  if (values.time.length < 1) {
    errors.time = "Please select a time option";
  }
  return errors;
};
export default function FreeQuote() {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [quoteForm, setQuoteForm] = useState({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    number: "",
    email: "",
    anything_to_know: "",
    service_checked: [],
    time: [],
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleServices = (e) => {
    if (e.target.checked) {
      setQuoteForm({
        ...quoteForm,
        service_checked: _.concat(quoteForm.service_checked, e.target.value),
      });
    } else {
      const selectedOption = _.find(
        quoteForm.service_checked,
        (item) => item === e.target.value
      );
      setQuoteForm({
        ...quoteForm,
        service_checked: _.remove(quoteForm.service_checked, function (n) {
          return n !== selectedOption;
        }),
      });
    }
  };
  const handleTime = (e) => {
    if (e.target.checked) {
      setQuoteForm({
        ...quoteForm,
        time: _.concat(quoteForm.time, e.target.value),
      });
    } else {
      const selectedOption = _.find(
        quoteForm.time,
        (item) => item === e.target.value
      );
      setQuoteForm({
        ...quoteForm,
        time: _.remove(quoteForm.time, function (n) {
          return n !== selectedOption;
        }),
      });
    }
  };
  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/a1baa640-2cb9-11ee-814b-67c4b3b91917";
  const emailJsKey = `pE_LQ7VDgU-TFkLeo`;
  const emailJsService = `service_vrhngi6`;
  const emailJsTemplate = `template_4ars5gi`;
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(quoteForm));
    setSubmitted(true);
    emailjs
      .send(emailJsService, emailJsTemplate, quoteForm, {
        publicKey: emailJsKey,
      })
      .then(
        (response) => {
          console.log("success", response.status, response.text, response);

          setSuccess(true);
        },
        (error) => {
          console.log("failed", error);
        }
      );
  };

  useEffect(() => {
    const finishSubmit = () => {
      fetch(FORM_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(quoteForm),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Form response was not ok");
          }
        })
        .catch((err) => {
          // Submit the form manually
        });
    };
    if (Object.keys(errors).length === 0 && submitted) {
      setSuccess(true);
      finishSubmit();
    } else {
      return;
    }
  }, [errors, submitted, quoteForm]);
  if (success) {
    return navigate("/thankyou");
  }

  return (
    <div className="freeQuoteContainer">
      <h1 className="titleGreen">Get a Free Quote</h1>
      <h2>
        Serving Cambridge, Guelph, Kitchener, Waterloo, Etobicoke, & Mississauga
      </h2>
      <div className="contactContainer wrapper">
        <ReusableContact />
        <div className="contactFormContainer">
          <h3>
            Sign up for a FREE quote for any of our services and we will connect
            with you as soon as possible to arrange a convenient time.
          </h3>
          <form
            onSubmit={handleSubmit}
            action={FORM_ENDPOINT}
            method="post"
            name="freeQuote"
            acceptCharset="UTF-8"
            data-netlify="true"
            netlify-honeypot="bot-field"
          >
            {Object.keys(errors).length !== 0 && (
              <div className="error">
                {errors.first_name && <p>{errors.first_name}</p>}
                {errors.last_name && <p>{errors.last_name}</p>}
                {errors.email && <p>{errors.email}</p>}
                {errors.number && <p>{errors.number}</p>}
                {errors.address && <p>{errors.address}</p>}
                {errors.city && <p>{errors.city}</p>}
                {errors.service_checked && <p>{errors.service_checked}</p>}
                {errors.time && <p>{errors.time}</p>}
                {errors.anything_to_know && <p>{errors.anything_to_know}</p>}
              </div>
            )}
            <p>
              <span>*</span> Indicates required field
            </p>
            <h4 className="formLabel">
              Name <span>*</span>
            </h4>
            <div className="formNameContainer formSection">
              <div>
                <label htmlFor="first_name">First</label>

                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  placeholder="First"
                  onChange={(e) =>
                    setQuoteForm({
                      ...quoteForm,
                      first_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label htmlFor="last_name">Last</label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Last"
                  onChange={(e) =>
                    setQuoteForm({
                      ...quoteForm,
                      last_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="email" className="formLabel">
                Email <span>*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={(e) =>
                  setQuoteForm({
                    ...quoteForm,
                    email: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="number" className="formLabel">
                Phone Number <span>*</span>
              </label>
              <input
                id="number"
                name="number"
                type="tel"
                placeholder="000-000-0000"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}"
                onChange={(e) =>
                  setQuoteForm({
                    ...quoteForm,
                    number: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="address" className="formLabel">
                Address <span>*</span>
              </label>
              <input
                id="address"
                name="address"
                type="text"
                onChange={(e) =>
                  setQuoteForm({
                    ...quoteForm,
                    address: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="city" className="formLabel">
                City <span>*</span>
              </label>
              <input
                id="city"
                name="city"
                type="text"
                onChange={(e) =>
                  setQuoteForm({
                    ...quoteForm,
                    city: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection ">
              <label htmlFor="serviceQuote" className="formLabel">
                Select all services you would like to receive a quote on.
                <span>*</span>
              </label>
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="formCheckbox"
              >
                <label>
                  Exterior Window Cleaning
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Exterior Window Cleaning "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Exterior Interior Window Cleaning "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Exterior & Interior Window Cleaning
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Eavestrough Cleaning "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Eavestrough Cleaning
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Siding Cleaning "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Siding Cleaning
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Driveway Power Washing "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Driveway Power Washing
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Deck Power Washing "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Deck Power Washing
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value=" Mosquito Or Tick Control "
                    className="checkbox"
                    onChange={(e) => handleServices(e)}
                  />
                  <span className="checkmark"></span>
                  Mosquito or Tick Control
                </label>
              </div>
            </div>
            <div className="flexContainer formSection ">
              <label htmlFor="time" className="formLabel">
                A quote usually takes around 15-20 minutes to complete. What
                times work best? <span>*</span>
              </label>
              <div
                role="group"
                aria-labelledby="checkbox-group"
                className="formCheckbox"
              >
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value="Weekday Daytime"
                    className="checkbox"
                    onChange={(e) => handleTime(e)}
                  />
                  <span className="checkmark"></span>
                  Weekday Daytime
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value="Weekday Evening"
                    className="checkbox"
                    onChange={(e) => handleTime(e)}
                  />
                  Weekday Evening
                  <span className="checkmark"></span>
                </label>
                <label>
                  <input
                    type="checkbox"
                    name="checked"
                    value="Weekend"
                    className="checkbox"
                    onChange={(e) => handleTime(e)}
                  />
                  <span className="checkmark"></span>
                  Weekend
                </label>
              </div>
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="anything_to_know" className="formLabel">
                Anything you'd like us to know?
              </label>
              <textarea
                name="anything_to_know"
                id="anything_to_know"
                cols="30"
                rows="5"
                onChange={(e) =>
                  setQuoteForm({
                    ...quoteForm,
                    anything_to_know: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <button type="submit" className="submitButton ">
              Submit
            </button>
            <div
              style={{
                textIndent: -99999,
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "absolute",
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="_gotcha"
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
