import React from "react";

// image imports
import vipRewards from "../../../media/vip-rewards-small.jpg";
import logo from "../../../media/green-right-small-logo_1.png";

const Vip = () => {
  return (
    <>
      <div className="wrapper">
        <div className="rewardsHeader">
          <img src={vipRewards} alt="VIP Rewards title" />
          <h1 className="visuallyHidden">VIP Rewards Program</h1>
          <div className="rewardsTitle">
            <p>
              Our <span className="companyAccent">Green Right</span> VIP Rewards
              Program is for our customers that want annual or biannual service.
            </p>
          </div>
        </div>

        <div className="rewardsQA">
          <div className="rewardsQuestion">
            <p>What do I get as a VIP Rewards member?</p>
          </div>
          <div className="rewardsAnswer">
            <ul>
              <li>
                <p>*Discounts on all of our services</p>
              </li>
              <li>
                <p>*Priority booking</p>
              </li>
            </ul>
          </div>
        </div>

        <div className="rewardsQA">
          <div className="rewardsQuestion">
            <p>How do I become a VIP Rewards member?</p>
          </div>
          <div className="rewardsAnswer">
            <p>
              *It's simple. All you have to do is select the VIP Rewards price
              as you are booking your service and you will be automatically
              enrolled as a VIP Rewards member.
            </p>
          </div>
        </div>

        <div className="rewardsQA">
          <div className="rewardsQuestion">
            <p>Is there a penalty for ending my VIP Rewards membership?</p>
          </div>
          <div className="rewardsAnswer">
            <p>
              *No. You can opt out of your VIP Rewards membership at any time
              without penalty as long as the provided service is cancelled more
              than 24 hours in advance of a booking.
            </p>
          </div>
        </div>

        <div className="rewardsQA">
          <div className="rewardsQuestion">
            <p>What happens next year?</p>
          </div>
          <div className="rewardsAnswer">
            <p>
              *As a VIP Rewards member your spot for next year will be reserved
              automatically. At the beginning of the year we will send you a
              reminder of the upcoming service at which point you can confirm
              your date (or pick a new one), pay your deposit to reserve your
              booking, or opt out.
            </p>
          </div>
        </div>

        <div className="rewardsExit">
          <p>
            If you have any other questions regarding our VIP Rewards Program
            contact us at:{" "}
          </p>
          <a href="mailto:info@greenrightwindowcleaning.com">
            info@greenrightwindowcleaning.com
          </a>
        </div>

        <div className="rewardsLogo">
          <img src={logo} alt="Green Right Window Cleaning logo" />
        </div>
      </div>
    </>
  );
};

export default Vip;
