import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import freeQuote from "../../../media/Get Your Free Quote Button.png";

const ServiceText = () => {
  const serviceTextMap = {
    "/windowCleaning": "windows cleaned",
    "/sidingWashing": "siding washed",
    "/eavestrough": "eavestrough cleaning",
    "/powerWashing": "power washing",
    "/mosquitoControl": "mosquito and tick control",
  };

  const [serviceText, setServiceText] = useState("");

  useEffect(() => {
    const currentURL = window.location.pathname;

    const matchedServiceText = serviceTextMap[currentURL] || "page not found";
    setServiceText(matchedServiceText);
  }, []);

  return (
    <div className="freeQuote">
      <h3>Want to know what it would cost to get {serviceText}?</h3>
      <div className="alwaysGreenQuoteButton">
        <NavLink to="/freeQuote">
          <img src={freeQuote} alt="Click here to get your free quote today!" />
        </NavLink>
      </div>
    </div>
  );
};

export default ServiceText;
