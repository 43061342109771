import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
// Header component
import Navigation from "./Navigations/Navigation";

// media import
import logo from "../../media/green-right-small-logo_1.png";
import { GiHamburgerMenu } from "react-icons/gi";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });
  const location = useLocation();
  const displayMenu = () => {
    setIsOpen((current) => !current);
  };
  const handleScroll = () => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 10) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    setIsOpen(false);
  }, [location]);
  const stickyClasses = classNames("headerSplash", {
    stickyHeader: isSticky,
  });
  return (
    <div className={stickyClasses}>
      <div className={isMobile ? "wrapper menuContainer" : "menuContainer"}>
        {isMobile && (
          <div>
            <button
              className="mobileMenuButton"
              type="button"
              onClick={displayMenu}
            >
              <GiHamburgerMenu />
              <p>Click here to open the menu</p>
            </button>
          </div>
        )}

        <div className="headerLogoContainer">
          <Link to="" className={isSticky ? "logoSticky" : "logo"}>
            <img src={logo} alt="Green Right Window Cleaning logo" />
          </Link>
        </div>

        {!isMobile && <Navigation isSticky={isSticky} />}
      </div>
      {isOpen && <Navigation isSticky={isSticky} />}
    </div>
  );
};

export default Header;
