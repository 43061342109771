import React from "react";

import ReusableContactFooter from "../ReusableContactFooter";

// image imports
import overallRating from "../../../media/4-8-on-5-rating.png";
import fiveStar from "../../../media/5-5-on-5-rating.png";
import fourFiveStar from "../../../media/4-5-on-5-rating.png";
import fourStar from "../../../media/4-on-5-rating.png";
import googLogo from "../../../media/Google Logo.jpg";
import fbLogo from "../../../media/FB Logo.jpg";
import hstarLogo from "../../../media/HomeStars-logo-3x.png";

const CustomerReviews = () => {
  return (
    <>
      <div className="wrapper">
        <div className="customerReviewTitle">
          <div className="reviewTitle">
            <h1 className="titleGreen">What Our Customers Are Saying!</h1>
            <div className="reviewScore">
              <img src={overallRating} alt="4.8 Stars out of 5" />
            </div>
            <p className="overallReviews">
              4.8 average rating from 79 customer surveys and reviews from:
            </p>
          </div>

          <div className="reviewSites">
            <ul>
              <li>
                <img src={googLogo} alt="Google Logo" />
              </li>
              <li>
                <img src={fbLogo} alt="Facebook Logo" />
              </li>
              <li>
                <img src={hstarLogo} alt="HomeStars Logo" />
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="customerReviewWindow">
        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "Thank you! I am very pleased with the work that you did. My
              windows are sparkling clean and the trim around my front windows
              was cleaned as requested."
            </p>
            <p className="cName">Sharon, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "They did an excellent job on the windows. Fast efficient we
              barely knew they were here."
            </p>
            <p className="cName">Vicki, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourFiveStar} alt="4.5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">"Very professional."</p>
            <p className="cName">Julie, Kitchener</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourFiveStar} alt="4.5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "Nick did an exceptional job with our windows. Thank you so much.
              I'm glad we chose the VIP program."
            </p>
            <p className="cName">Shamendree, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "A great crew of guys arrived at my house and took their time and
              did a very thorough job. They didn't leave until they were
              satisfied with their work. I'm very happy."
            </p>
            <p className="cName">Kieran, Guelph</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourStar} alt="4 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">"Windows look great!"</p>
            <p className="cName">Jackie, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "Jeff did a fantastic job as advertised. I can easily recommend
              him for your window cleaning needs."
            </p>
            <p className="cName">Sean, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourStar} alt="4 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "You did a fantastic job. Thank you so much for taking care of
              this."
            </p>
            <p className="cName">Bethany, Guelph</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "I am very happy with your service. Thank you."
            </p>
            <p className="cName">Angela, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "The young men were very courteous and professional. They worked
              diligently to get the job done."
            </p>
            <p className="cName">Susan, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourStar} alt="4 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "We had our windows and siding cleaned last week by Green Right
              Window Cleaning. They did an excellent job. They cleaned all
              interior and exterior windows as well as the screens. They were
              polite and professional. Very happy with how it turned out!"
            </p>
            <p className="cName">Nicole, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">"Very friendly and professional."</p>
            <p className="cName">Scott, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "You guys did a great job! I will definitely recommend your
              services to others."
            </p>
            <p className="cName">Jenn, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fourFiveStar} alt="4.5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "Very professional, great service, well done."
            </p>
            <p className="cName">Gareth, Cambridge</p>
          </div>
        </div>

        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="4.5 star rating" />
          </div>
          <div className="reviewMessage contentWrap">
            <p className="cMessage">
              "Thanks for the great job! The windows look great."
            </p>
            <p className="cName">Jennifer, Cambridge</p>
          </div>
        </div>
        <div className="reviewContent">
          <div className="reviewRating contentWrap">
            <img src={fiveStar} alt="5 star rating" />
          </div>
          <div className="reviewSecondary contentWrap">
            <p className="cMessage">
              "Thank you so much for that. Your customer service is
              outstanding."
            </p>
            <p className="cName">Lorie, Cambridge</p>
          </div>
        </div>
      </div>

      <ReusableContactFooter />
    </>
  );
};

export default CustomerReviews;
