import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReusableContactFooter from "../ReusableContactFooter";

const validate = (values) => {
  const errors = {};

  if (!values.firstName) {
    errors.firstName = "Required";
  } else if (values.firstName.length > 15) {
    errors.firstName = "Must be 15 characters or less";
  }

  if (!values.lastName) {
    errors.lastName = "Required";
  } else if (values.lastName.length > 20) {
    errors.lastName = "Must be 20 characters or less";
  }

  if (!values.address) {
    errors.address = "Required";
  } else if (values.address.length < 1) {
    errors.address = "Must include address";
  }

  if (!values.city) {
    errors.city = "Required";
  } else if (values.city.length < 1) {
    errors.city = "Must include city name";
  }
  if (!values.number) {
    errors.number = "Required";
  } else if (/^[+]?[0-9]{9,12}$/.test(values.number)) {
    errors.number = "Invalid phone number";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }
  if (!values.position) {
    errors.position = "Required";
  } else if (values.position.length < 1) {
    errors.position = "Please select an option";
  }
  if (values.anythingToKnow.length > 140) {
    errors.anythingToKnow = "Max 140 characters";
  }
  return errors;
};
export default function JobOpportunities() {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [jobForm, setJobForm] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    number: "",
    email: "",
    anythingToKnow: "",
    position: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/8a01f4e0-2cb9-11ee-9314-312e17da8d1e";
  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(jobForm));
    setSubmitted(true);
  };

  useEffect(() => {
    const finishSubmit = () => {
      fetch(FORM_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jobForm),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Form response was not ok");
          }
        })
        .catch((err) => {});
    };
    if (Object.keys(errors).length === 0 && submitted) {
      setSuccess(true);
      finishSubmit();
    } else {
      return;
    }
  }, [errors, submitted, jobForm]);
  if (success) {
    return navigate("/thankyou");
  }
  return (
    <div className="wrapper jobOppContainer ">
      <h1 className="titleGreen">Job Opportunities</h1>
      <p className="jobOppP">
        We are always looking for good, hard-working, committed people to join
        our team!
      </p>

      <section>
        <h2>
          Here are some of the opportunities available on our Green Right Window
          Cleaning Team:
        </h2>
        <article>
          <h3>Assistant Manager</h3>
          <p>
            Our Assistant Managers are leaders on both the production and
            marketing sides of the business. Assistant mangers lead a crew while
            involved with the work themselves and they are the lead connection
            for the customer. They also help lead the marketing side of the
            business while providing quotes to customers. It's a position for
            those that like higher levels of responsibility and opportunities
            for growth and development. Our Assistant Managers typically earn
            about $16-19 per hour but can earn more through additional
            incentives. A vehicle is required for the Assistant Manager
            position.
          </p>
        </article>
        <article>
          <h3>Service Technicians</h3>
          <p>
            Our Service Technicians are responsible for providing the service to
            our clients. To be a technician customer service must be a priority
            and you do need to be in relatively good physical shape to handle
            the equipment and work over a full work week. Technicians typically
            earn about $15-17 per hour and most are full-time 25-35 hours per
            week.
          </p>
        </article>
        <article>
          <h3>Sales Associate</h3>
          <p>
            Our Sales Associates are responsible for marketing and providing
            quotes to clients. Sales Associates, don't need to have experience
            as they will be trained in marketing, sales, and leadership for
            those looking to grow and develop into Sales Managers. Sales
            Associates must have good people and communication skills, previous
            experience is not needed but can be an asset. Sales Associates
            typically earn $15-20 per hour, though can earn more through their
            incentive based compensation. Full-time and flex-time positions are
            available. Sales Associates determine their own work schedule,
            though a minimum of 10 hours per week is required, along with
            evening and weekend availability.
          </p>
        </article>
      </section>
      <section>
        <h3 className="jobOppFormHeader">
          If you think you have what it takes and want to learn more about our
          positions, please connect with us below. We'd love to hear from you!
        </h3>
        <div className="contactFormContainer ">
          <form
            onSubmit={handleSubmit}
            action={FORM_ENDPOINT}
            method="post"
            acceptCharset="UTF-8"
          >
            {Object.keys(errors).length !== 0 && (
              <div className="error">
                {errors.firstName && <p>{errors.firstName}</p>}
                {errors.lastName && <p>{errors.lastName}</p>}
                {errors.email && <p>{errors.email}</p>}
                {errors.number && <p>{errors.number}</p>}
                {errors.address && <p>{errors.address}</p>}
                {errors.city && <p>{errors.city}</p>}
                {errors.position && <p>{errors.position}</p>}
                {errors.anythingToKnow && <p>{errors.anythingToKnow}</p>}
              </div>
            )}
            <p>
              <span>*</span> Indicates required field
            </p>
            <h4 className="formLabel">
              Name <span>*</span>
            </h4>
            <div className="formNameContainer formSection">
              <div>
                <label htmlFor="firstName">First</label>

                <input
                  id="firstName"
                  name="firstName"
                  type="text"
                  placeholder="First"
                  onChange={(e) =>
                    setJobForm({
                      ...jobForm,
                      firstName: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label htmlFor="lastName">Last</label>
                <input
                  id="lastName"
                  name="lastName"
                  type="text"
                  placeholder="Last"
                  onChange={(e) =>
                    setJobForm({
                      ...jobForm,
                      lastName: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="email" className="formLabel">
                Email <span>*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    email: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="number" className="formLabel">
                Phone Number <span>*</span>
              </label>
              <input
                id="number"
                name="number"
                type="tel"
                placeholder="000-000-0000"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    number: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="address" className="formLabel">
                Address <span>*</span>
              </label>
              <input
                id="address"
                name="address"
                type="text"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    address: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="city" className="formLabel">
                City <span>*</span>
              </label>
              <input
                id="city"
                name="city"
                type="text"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    city: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection ">
              <label htmlFor="position" className="formLabel">
                What position are you interested in? <span>*</span>
              </label>
              <select
                name="position"
                id="position"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    position: e.target.value,
                  })
                }
                required
              >
                <option value="assistantManager">Assistant Manager</option>
                <option value="serviceTechnician">Service Technician</option>
                <option value="salesAssociate">Sales Associate</option>
              </select>
            </div>

            <div className="flexContainer formSection">
              <label htmlFor="anythingToKnow" className="formLabel">
                Anything you'd like us to know?
              </label>
              <textarea
                name="anythingToKnow"
                id="anythingToKnow"
                cols="30"
                rows="5"
                onChange={(e) =>
                  setJobForm({
                    ...jobForm,
                    anythingToKnow: e.target.value,
                  })
                }
              ></textarea>
            </div>
            <button type="submit" className="submitButton ">
              Submit
            </button>
            <div
              style={{
                textIndent: -99999,
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "absolute",
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="_gotcha"
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
          </form>
        </div>
      </section>
      <ReusableContactFooter />
    </div>
  );
}
