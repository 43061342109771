import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import ReusableContactFooter from "../ReusableContactFooter";

// image imports
import greenRightGuarantee from "../../../media/Green Right Guaranteed.png";
import leafWater from "../../../media/water-drop-off-plant.jpg";
import vipSmall from "../../../media/vip-rewards-small.jpg";
import freeQuote from "../../../media/Get Your Free Quote Button.png";

const About = ({ title }) => {
  const location = useLocation();
  return (
    <>
      <div className="wrapper">
        <div className="aboutGreenRight">
          <h1 className="titleGreen">
            {location.pathname === "/aboutUs" ? "About Us" : title}
          </h1>
          <p>
            Green Right Window Cleaning provides residential window cleaning,
            siding washing, and power washing to customers all over Kitchener,
            Waterloo, Cambridge, Guelph, Etobicoke and Mississauga. We have more
            than 15 years experience and we pride ourselves on our quality work
            at a great price!
          </p>
        </div>

        <div className="aboutUsContent">
          <div className="aboutSubcontent">
            <div className="subcontentPhoto">
              <img src={leafWater} alt="water drop off plant" />
            </div>
            <div className="subcontentWindow">
              <h2>Always Green</h2>
              <p>
                Not only do we provide a quality service but we do it the right
                way; the Green way! We clean your windows with the purest of
                water and everything we use is eco-friendly. It's important to
                us that we leave the smallest footprint possible. Click below to
                learn more about how we do this.
              </p>
              <button className="learnMore">
                <NavLink to="/alwaysGreen">Learn More</NavLink>
              </button>
            </div>
          </div>

          <div className="aboutSubcontent">
            <div className="subcontentPhoto">
              <img
                src={greenRightGuarantee}
                alt="Green Right Guarateed Stamp"
              />
            </div>
            <div className="subcontentWindow">
              <h2>Our Guarantee</h2>
              <p>
                Our <span className="companyAccent">Green Right</span>{" "}
                Guarantee: Great Quality, Great Price, Always Green! We believe
                that you hire someone to take care of your home, you should get
                a quality job and great service all for a reasonable price. We
                also believe that it should be done in a way that keeps our
                world beautiful! Click below to learn more about our guarantee!
              </p>
              <button className="learnMore">
                <NavLink to="/ourGuarantee">Learn More</NavLink>
              </button>
            </div>
          </div>

          <div className="aboutSubcontent">
            <div className="subcontentPhoto">
              <img
                src={vipSmall}
                alt="Learn more about our VIP rewards program"
              />
            </div>
            <div className="subcontentWindow">
              <h2>Our VIP Rewards Program</h2>
              <p>
                Our VIP Rewards program is built to reward our annual customers
                through service discounts and priority booking. By signing up as
                a VIP Rewards member you'll get are incredible services at an
                even greater price, while securing your spot for as long as you
                want it! Click below to learn more about our VIP Rewards
                program.
              </p>
              <button className="learnMore">
                <NavLink to="/ourVipRewardsProgram">Learn More</NavLink>
              </button>
            </div>
          </div>

          <div className="onlineQuote">
            <h3>It only takes a couple of minute to set up a quote!</h3>
            <div className="quoteButton">
              <NavLink to="/freeQuote">
                <img
                  src={freeQuote}
                  alt="Click here to get your free quote today!"
                />
              </NavLink>
            </div>
          </div>

          <ReusableContactFooter />
        </div>
      </div>
    </>
  );
};

export default About;
