import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../../media/green-right-small-logo_1.png";

export default function Footer() {
  return (
    <footer className="footer">
      <div className="wrapper footerContainer">
        <h3 className="footerHeading">
          <NavLink to="/freeQuote">Get A Free Quote Today!</NavLink>
        </h3>
        <nav className="footerNav">
          <ul>
            <li>
              <NavLink to="/aboutUs">
                <h4>About Green Right</h4>
              </NavLink>
            </li>
            <li>
              <NavLink to="/customerReviews">
                What Our Customers Are Saying!
              </NavLink>
            </li>
            <li>
              <NavLink to="/whyGreen">Why Green Right</NavLink>
            </li>
            <li>
              <NavLink to="alwaysGreen">Always Green!</NavLink>
            </li>
            <li>
              <NavLink to="/ourGuarantee">Our Green Right Guarantee</NavLink>
            </li>
            <li>
              <NavLink to="/ourVipRewardsProgram">
                Our VIP Rewards Program
              </NavLink>
            </li>
            <li>
              <NavLink to="/jobOpportunities">Job Opportunities</NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to="/ourServices">
                <h4>Our Services</h4>
              </NavLink>
            </li>
            <li>
              <NavLink to="/windowCleaning">Window Cleaning</NavLink>
            </li>
            <li>
              <NavLink to="/sidingWashing">Siding Washing</NavLink>
            </li>
            <li>
              <NavLink to="eavestrough">Eavestrough Cleaning</NavLink>
            </li>
            <li>
              <NavLink to="/powerWashing">Power Washing</NavLink>
            </li>
            <li>
              <NavLink to="/mosquitoControl">Mosquito & Tick Control</NavLink>
            </li>
          </ul>
          <div className="footerAddress">
            <div className="footerLogo">
              <img src={logo} alt="Green Right Window Cleaning logo" />
            </div>

            <address>
              <p>
                Call us: <a href="tel:+2263392998">226-339-3998</a>
              </p>
              <p>
                Email us:{" "}
                <a href="mailto:info@greenrightwindowcleaning.com">
                  info@greenrightwindowcleaning.com
                </a>
              </p>
            </address>
          </div>
        </nav>
        <div className="copyrightContainer wrapper">
          <p>
            ©2022 Green Right Window Cleaning <span>|</span>
            <NavLink to="/privacyPolicy">Privacy Policy</NavLink>
            <span>|</span>
            <NavLink to="/terms">Terms</NavLink>
          </p>
        </div>
      </div>
    </footer>
  );
}
