import React from "react";
import Included from "./Included";
import ServiceText from "./ServiceText";

// image imports
import mosquitoHeader from "../../../media/Mosquito & Tick Control Header.png";
import ecoSprayLogo from "../../../media/Eco Spray.png";

const Mosquito = () => {
  return (
    <>
      <div className="servicesHeader">
        <img
          src={mosquitoHeader}
          alt="Window Cleaning title over an image of a house"
        />
      </div>

      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Mosquito & Tick Control</h1>
          <h2 className="mosquitoSubtitle">**100% Safe & Natural**</h2>
          <div className="mosquitoPartnership">
            <p>In Partnership With</p>
            <div className="partnershipLogo">
              <a
                href="https://www.ecospray.ca/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={ecoSprayLogo} alt="eco spray company logo" />
              </a>
            </div>
          </div>

          <div className="mosquitoContent">
            <p>
              Our professional technicians use a 100% natural product that is
              completely safe for all people, animals, plants, and good insects
              like bees and butterflies. Spraying your backyard creates a full
              barrier to keep both mosquitoes and ticks away. We offer both
              1-time spraying for events or get-togethers and seasonal control
              so you can enjoy your backyard all summer long!
            </p>
          </div>

          <div className="mosquitoContent">
            <h3 className="mosquitoSpecial">Seasonal Control & Prevention</h3>
            <p>
              Our seasonal control and prevention provides the most complete
              protection for you and your family from mosquitoes and ticks.
              Seasonal control typically includes 6 sprays throughout the season
              usually every 2-4 weeks to ensure both immediate control and
              residual prevention. Timing between sprays depends on the property
              and weather, but typically would be every 2-3 weeks early in the
              season and every 3-5 later in the season.
            </p>
          </div>

          <div className="mosquitoContent">
            <h3 className="mosquitoSpecial">1-Time Spray for Special Events</h3>
            <p>
              Whether for an outdoor wedding, special event, or summer party,
              our 1-time spray will make your event that much more enjoyable
              ensuring only the guests you invite show up! For a 1-time spray
              we'd apply the day before your big event to ensure maximum
              success.
            </p>
          </div>

          <div className="mosquitoSubheader">
            <h3>What You Should Know...</h3>
          </div>

          <div className="mosquitoContent">
            <h3>Our Product is 100% Safe and 100% Natural</h3>
            <p>
              We believe in using natural products that are safe for people,
              animals, plants and the environment. Our spray is completely
              natural with no chemicals added. The main ingredient is highly
              concentrated garlic oil which may leave your backyard with a mild
              smell that resembles a delicious backyard bbq, though will only
              last for a few hours for us, though for a long time for mosquitoes
              and ticks.
            </p>
          </div>

          <div className="mosquitoContent">
            <h3>Our Application is Long Lasting</h3>
            <p>
              Typically each application will last from 2-5 weeks depending on
              the season, the weather and the environment. At the beginning of
              the season we typically apply every 2-3 weeks when there is more
              precipitation and growth, and later every 3-5 weeks when it is
              drier. Each application is applied before the residual has expired
              for constant protection and coverage all season long.
            </p>
          </div>

          <div className="mosquitoContent">
            <h3>It Creates a Barrier Around Your Property</h3>
            <p>
              We use powered backpack sprayers to treat all of your foliage,
              trees, bushes, shrubs, grass, under your deck, behind your shed
              and in all those places where mosquitoes and ticks like to hide.
              Our specially designed product is great at creating a barrier
              around your property to keep those mosquitoes and ticks away.
            </p>
          </div>

          <div className="mosquitoContent">
            <h3>Our Service is Guaranteed</h3>
            <p>
              Given that we work within a natural environment and every property
              is different, there is always the odd time that either the weather
              or some other circumstance affects the barrier created around your
              property. If at anytime you feel that the application has not
              worked just let us know and we will reapply, no questions asked.
            </p>
          </div>

          <div className="mosquitoContent">
            <h3>You Can Help</h3>
            <p>
              Eliminating any standing water on your property and cleaning up
              debris is a great place to start to help reduce mosquitoes.
              Mosquitoes love damp areas so emptying out that tire full of
              water, picking up that pile of leaves and keeping your
              eavestroughs cleaned out will dramatically help. For ticks, they
              love long grass and wood, so ensuring all your grass is cut
              regularly and removing any unnecessary piles of wood can help
              eliminate their favourite spots to hide.
            </p>
          </div>

          <ServiceText />
          <Included />
        </div>
      </div>
    </>
  );
};

export default Mosquito;
