import React from "react";
import Included from "./Included";
import ServiceText from "./ServiceText";

// image imports
import powerWashingHeader from "../../../media/Power Washing Header.png";

const PowerWashing = () => {
  return (
    <>
      <div className="servicesHeader">
        <img
          src={powerWashingHeader}
          alt="Window Cleaning title over an image of a house"
        />
      </div>

      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Power Washing</h1>

          <div className="windowContent">
            <p>
              Power washing uses the power of pressurized water to remove dirt
              and grime from the surfaces around your home. All of our power
              washing is done using an eco-friendly cleaning solution
              specifically made for the surfaces we wash.
            </p>
          </div>

          <div className="windowContent">
            <h2>Power Washing Services</h2>
            <p>
              Our professional technicians can restore the beauty of many of the
              surfaces around your home. Whether it's your driveway, your
              walkways, your patio, or your deck, we have the tools to make all
              these surfaces look like new again!
            </p>
          </div>

          <div className="windowContent">
            <h2>The Benefits of Power Washing</h2>
            <p>
              The surfaces around our home go through a lot through the course
              of a year. Not only do they face a tonne of use, but they have to
              survive our Canadian winter. Power washing removes the dirt,
              algae, mildew, and salt that's built up on the surface restoring
              it to its beautiful self. Regular power washing also prevents
              deterioration and keeps the surfaces around your home in top
              condition. Not only will they look like new again, but they will
              last longer saving you thousands of dollars through the lifetime
              of your home.{" "}
            </p>
          </div>

          <ServiceText />
          <Included />
        </div>
      </div>
    </>
  );
};

export default PowerWashing;
