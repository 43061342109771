import { Link } from "react-router-dom";
import ReusableContactFooter from "./ReusableContactFooter";
import googleLogo from "../../media/Google Logo.jpg";
export default function ThankYouPayment() {
  return (
    <>
      <div className="wrapper payment">
        <h1 className="titleGreen">
          Thank you for choosing Green Right Window Cleaning
        </h1>
        <p>
          Please leave a Google Review because we would love to hear your
          thoughts
        </p>
        <div className="googleContainer">
          <Link
            to="https://www.google.com/search?sca_esv=556313000&sxsrf=AB5stBiJ3xvAcKzVmLNB6OcjJdfiNJcQ0A:1691850656130&q=Green+right+window+cleaning+reviews&uds=H4sIAAAAAAAA_-Py5WIPSi3LTC0vFpJOL0pNzVMoykzPKFEoz8xLyS9XSM5JTczLzEs3ECpSdsctrVAEMQMApYLgBU8AAAA&sa=X&ved=2ahUKEwj_ofzqqteAAxWZMjQIHTQLDnUQxKsJegQICxAB&ictx=0&biw=1920&bih=969&dpr=1#lrd=0x882b89744ea673a5:0x3ebe560887685484,3,,,,"
            target="_blank"
          >
            <img className="googleThank" src={googleLogo} alt="" />
          </Link>
        </div>

        <ReusableContactFooter />
      </div>
    </>
  );
}
