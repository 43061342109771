import React from "react";
import logo from "../../media/green-right-small-logo_1.png";
export default function ReusableContactFooter() {
  return (
    <div className="contactSection">
      <h3>
        Call us at <span className="companyAccent">226-339-3998</span>
      </h3>
      <img src={logo} alt="Green Right Window Cleaning logo" />
    </div>
  );
}
