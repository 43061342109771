import React from "react";

import logo from "../../media/green-right-small-logo_1.png";

export default function ReusableContact() {
  return (
    <div className="reusableContactContainer">
      <div className="logoContainer">
        <img src={logo} alt="Green Right Window Cleaning logo" />
      </div>
      <address className="reusableContact">
        <p>
          Phone: <a href="tel:+2263393998">226-339-3998</a>
        </p>
        <p>
          Email:{" "}
          <a href="mailto:info@greenrightwindowcleaning.com">
            info@greenrightwindowcleaning.com
          </a>
        </p>
      </address>
    </div>
  );
}
