import React from "react";
import { NavLink } from "react-router-dom";
import { GoChevronLeft } from "react-icons/go";
export default function SubNavigation({
  setIsAboutSelected,
  isAboutSelected,
  setIsServiceSelected,
  isServiceSelected,
}) {
  const handleBack = () => {
    setIsServiceSelected(false);
    setIsAboutSelected(false);
  };

  const aboutMenu = [
    { title: "Back", icon: <GoChevronLeft />, onClick: handleBack },
    { navLink: <NavLink to="/aboutUs">About Us</NavLink> },
    { navLink: <NavLink to="/customerReviews">Customer Review</NavLink> },
    { navLink: <NavLink to="/alwaysGreen">Always Green</NavLink> },
    { navLink: <NavLink to="/ourGuarantee">Our Guarantee</NavLink> },
    {
      navLink: (
        <NavLink to="/ourVipRewardsProgram">Our VIP Rewards Program</NavLink>
      ),
    },
    { navLink: <NavLink to="/jobOpportunities">Job Opportunities</NavLink> },
  ];
  const serviceMenu = [
    { title: "Back", icon: <GoChevronLeft />, onClick: handleBack },
    { navLink: <NavLink to="/ourServices">Our Services</NavLink> },
    { navLink: <NavLink to="/windowCleaning">Window Cleaning</NavLink> },
    { navLink: <NavLink to="/sidingWashing">Siding Washing</NavLink> },
    { navLink: <NavLink to="/eavestrough">Eavestrough Cleaning</NavLink> },
    {
      navLink: <NavLink to="/powerWashing">Power Washing</NavLink>,
    },
    {
      navLink: <NavLink to="/mosquitoControl">Mosquite & Tick Control</NavLink>,
    },
  ];
  return (
    <div>
      {isServiceSelected && (
        <ul className="mobileNav">
          {serviceMenu.map((item, i) => {
            return (
              <li key={i} onClick={item.onClick}>
                {item.icon}
                {item.title}
                {item.navLink}
              </li>
            );
          })}
        </ul>
      )}
      {isAboutSelected && (
        <ul className="mobileNav  ">
          {aboutMenu.map((item, i) => {
            return (
              <li key={i} onClick={item.onClick}>
                {item.icon}
                {item.title}
                {item.navLink}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
