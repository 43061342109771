import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { GoChevronRight } from "react-icons/go";
import SubNavigation from "./SubNavigation";

const Navigation = ({ isSticky }) => {
  const [isServiceSelected, setIsServiceSelected] = useState(false);
  const [isAboutSelected, setIsAboutSelected] = useState(false);

  const location = useLocation();
  const isMobile = useMediaQuery({ query: `(max-width: 800px)` });

  const displayServiceMenu = () => {
    setIsServiceSelected(true);
  };
  const displayAboutMenu = () => {
    setIsAboutSelected(true);
  };
  const handleHighlight = (name) => {
    if (location.pathname === name) {
      return "highlight";
    }
  };
  return (
    <nav className="navigation">
      {isMobile && location.pathname !== "" ? (
        <div className="mobileNavContainer">
          <ul
            className={
              isAboutSelected || isServiceSelected
                ? "mobileNav moveAway"
                : "mobileNav mainMobileNav" || (isSticky && "mobileSticky")
            }
          >
            <li>
              <NavLink to="">Home</NavLink>
            </li>

            <li
              className={handleHighlight("/ourServices")}
              onClick={displayServiceMenu}
            >
              Our Services <GoChevronRight />
            </li>
            <li
              className={handleHighlight("/aboutUs")}
              onClick={displayAboutMenu}
            >
              About Us <GoChevronRight />
            </li>
            <li>
              <NavLink to="/freeQuote">Get A Quote</NavLink>
            </li>
            <li>
              <NavLink to="/contactUs"> Contact Us</NavLink>
            </li>
            <li>
              <NavLink to="/payment">Make A Payment</NavLink>
            </li>
          </ul>
          <div
            className={
              isAboutSelected || isServiceSelected ? "moveBack" : "subMobileNav"
            }
          >
            <SubNavigation
              setIsAboutSelected={setIsAboutSelected}
              setIsServiceSelected={setIsServiceSelected}
              isAboutSelected={isAboutSelected}
              isServiceSelected={isServiceSelected}
            />
          </div>
        </div>
      ) : null}

      {!isMobile && (
        <ul
          className={
            isSticky ? "mobileSticky mobileNav deskNav" : "mobileNav deskNav"
          }
        >
          <li>
            <NavLink to="">Home</NavLink>
          </li>
          <li
            onMouseOver={() => {
              setIsServiceSelected(true);
            }}
            onMouseOut={() => setIsServiceSelected(false)}
          >
            <NavLink to="/ourServices">Our Services</NavLink>
            {isServiceSelected && (
              <ul className="subMenu">
                <li>
                  <NavLink to="/windowCleaning">Window Cleaning</NavLink>
                </li>
                <li>
                  <NavLink to="/sidingWashing">Siding Washing</NavLink>
                </li>
                <li>
                  <NavLink to="/eavestrough">Eavestrough Cleaning</NavLink>
                </li>
                <li>
                  <NavLink to="/powerWashing">Power Washing</NavLink>
                </li>
                <li>
                  <NavLink to="/mosquitoControl">
                    Mosquite & Tick Control
                  </NavLink>
                </li>
              </ul>
            )}
          </li>
          <li
            onMouseOver={() => {
              setIsAboutSelected(true);
            }}
            onMouseOut={() => setIsAboutSelected(false)}
          >
            <NavLink to="/aboutUs">About Us</NavLink>
            {isAboutSelected && (
              <ul className="subMenu">
                <li>
                  <NavLink to="/customerReviews">
                    What Our Customers Are Saying!
                  </NavLink>
                </li>
                <li>
                  <NavLink to="alwaysGreen">Always Green!</NavLink>
                </li>
                <li>
                  <NavLink to="/ourGuarantee">
                    Our Green Right Guarantee
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/ourVipRewardsProgram">
                    Our VIP Rewards Program
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/jobOpportunities">Job Opportunities</NavLink>
                </li>
              </ul>
            )}
          </li>
          <li>
            <NavLink to="/freeQuote">Get a Quote</NavLink>
          </li>
          <li>
            <NavLink to="/contactUs">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/payment">Make a Payment</NavLink>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navigation;
