import React from "react";
import { NavLink } from "react-router-dom";
import ReusableContactFooter from "../ReusableContactFooter";

import freeQuote from "../../../media/Get Your Free Quote Button.png";

const Guarantee = () => {
  return (
    <>
      <div className="wrapper">
        <div className="aboutGreenRight">
          <h1 className="titleGreen">Our Guarantee</h1>
        </div>

        <div className="guaranteeWindow">
          <div className="guaranteeContent">
            <div className="guaranteeTitle">
              <h2>Great Quality</h2>
            </div>
            <div className="guaranteeInfo">
              <p>
                When you hire us to work on your home you can expect great
                quality. All of our services come with a{" "}
                <span className="guaranteeAccent">100% quality guarantee</span>.
                We don't accept payment unless you are happy with the work
                you've received. If for some reason you notice something a few
                days later, we will happily come back to ensure you are getting
                the job you deserve!
              </p>
            </div>
          </div>

          <div className="guaranteeContent">
            <div className="guaranteeTitle">
              <h2>Great Price</h2>
            </div>
            <div className="guaranteeInfo">
              <p>
                At Green Right we strive to provide our customer's with the best
                possible prices for the best quality of work! Although you could
                definitely find a cheaper "window cleaner", for a professional
                service, with professionally trained staff, $2 million liability
                insurance, full worker's compensation coverage, and a quality
                guarantee, our prices are tough to beat. If you find a
                competitor that beats our pricing let us know!
              </p>
            </div>
          </div>

          <div className="guaranteeContent">
            <div className="guaranteeTitle">
              <h2>Always Green</h2>
            </div>
            <div className="guaranteeInfo">
              <p>
                The environment is important and we believe it is our duty to
                take care of it. We care about your property and everything on
                it, and so everything we use is eco-friendly, from the purified
                water, to the eco-friendly cleaning solutions. Although we all
                make a footprint in the world, we believe we are doing our part
                to minimalize ours.
              </p>
            </div>
          </div>
        </div>

        <div className="freeQuote">
          <h3>It only takes a couple of minute to set up a quote!</h3>
          <NavLink to="/freeQuote" className="quoteButton">
            <img
              src={freeQuote}
              alt="Click here to get your free quote today!"
            />
          </NavLink>
        </div>

        <ReusableContactFooter />
      </div>
    </>
  );
};

export default Guarantee;
