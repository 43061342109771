import React from "react";
import { Link } from "react-router-dom";

import ReusableContactFooter from "./ReusableContactFooter";
export default function Payment() {
  return (
    <div className="wrapper payment">
      <h1 className="titleGreen">Make a Payment</h1>
      <p>
        To make a payment to Green Right Window Cleaning, please ensure you have
        your amount owing, and click the button below.
      </p>
      <div className="paymentButtonContainer">
        <Link
          className="paymentButton"
          target="_blank"
          to="https://zohosecurepay.com/checkout/pvc0pvq-9hskld4wojyp6/Balance-Due"
        >
          Make a Payment
        </Link>
      </div>
      <p>
        *** if you need any help with your payment feel free to contact us via
        email at{" "}
        <a className="green" href="mailto:info@greenrightwindowcleaning.com">
          info@greenrightwindowcleaning.com
        </a>{" "}
        or by phone at <a href="tel:+226-339-3998">226-339-3998</a>. ***
      </p>

      <ReusableContactFooter />
    </div>
  );
}
