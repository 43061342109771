import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ReusableContact from "./ReusableContact";
import emailjs from "@emailjs/browser";

const validate = (values) => {
  const errors = {};

  if (!values.first_name) {
    errors.first_name = "Required";
  } else if (values.first_name.length > 20) {
    errors.first_name = "First Name must be 20 characters or less";
  }
  if (!values.last_name) {
    errors.last_name = "Required";
  } else if (values.last_name.length > 20) {
    errors.last_name = "Last Name must be 20 characters or less";
  }

  if (!values.address) {
    errors.address = "Required";
  } else if (values.address.length < 1) {
    errors.address = "Must include address";
  }

  if (!values.city) {
    errors.city = "Required";
  } else if (values.city.length < 1) {
    errors.city = "Must include city name";
  }
  if (!values.number) {
    errors.number = "Required";
  } else if (!/^\d{10}$|^(\d{3}-){2}\d{4}$/.test(values.number)) {
    errors.number = "Invalid phone number";
  }
  if (!values.email) {
    errors.email = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = "Invalid email address";
  }

  if (!values.how_can_we_help) {
    errors.how_can_we_help = "Please enter response";
  } else if (values.how_can_we_help.length > 140) {
    errors.how_can_we_help = "Max 140 characters";
  }
  return errors;
};

export default function Contact() {
  const [submitted, setSubmitted] = useState(false);
  const [success, setSuccess] = useState(false);
  const [contactForm, setContactForm] = useState({
    first_name: "",
    last_name: "",
    address: "",
    city: "",
    number: "",
    email: "",
    how_can_we_help: "",
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const FORM_ENDPOINT =
    "https://public.herotofu.com/v1/8ea796b0-2c8e-11ee-8cff-57ce276e0e2f";
  const emailJsKey = `pE_LQ7VDgU-TFkLeo`;
  const emailJsService = `service_vrhngi6`;
  const emailJsTemplate = `template_rne5osx`;

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrors(validate(contactForm));
    setSubmitted(true);
    emailjs
      .send(emailJsService, emailJsTemplate, contactForm, {
        publicKey: emailJsKey,
      })
      .then(
        (response) => {
          console.log("success", response.status, response.text, response);
          setSuccess(true);
        },
        (error) => {
          console.log("failed", error);
        }
      );
  };

  useEffect(() => {
    const finishSubmit = () => {
      fetch(FORM_ENDPOINT, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(contactForm),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Form response was not ok");
          }
        })
        .catch((err) => {
          // Submit the form manually
        });
    };
    if (Object.keys(errors).length === 0 && submitted) {
      setSuccess(true);
      finishSubmit();
    } else {
      return;
    }
  }, [errors, submitted, contactForm]);
  if (success) {
    return navigate("/thankyou");
  }
  return (
    <div>
      <h1 className="titleGreen">Contact Us</h1>
      <div className="contactContainer wrapper">
        <ReusableContact />
        <div className="contactFormContainer">
          <h3>
            Let us know what we can do to help and we will be in contact with
            you shortly!
          </h3>
          <form
            id="contactForm"
            onSubmit={handleSubmit}
            action={FORM_ENDPOINT}
            method="post"
            acceptCharset="UTF-8"
          >
            {Object.keys(errors).length !== 0 && (
              <div className="error">
                {errors.first_name && <p>{errors.first_name}</p>}
                {errors.last_name && <p>{errors.last_name}</p>}
                {errors.address && <p>{errors.address}</p>}
                {errors.city && <p>{errors.city}</p>}
                {errors.number && <p>{errors.number}</p>}
                {errors.email && <p>{errors.email}</p>}
                {errors.how_can_we_help && <p>{errors.how_can_we_help}</p>}
              </div>
            )}
            <p>
              <span>*</span> Indicates required field
            </p>
            <h4 className="formLabel">
              Name <span>*</span>
            </h4>
            <div className="formNameContainer formSection">
              <div>
                <label htmlFor="first_name">First</label>

                <input
                  id="first_name"
                  name="first_name"
                  type="text"
                  placeholder="First"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      first_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
              <div>
                <label htmlFor="last_name">Last</label>
                <input
                  id="last_name"
                  name="last_name"
                  type="text"
                  placeholder="Last"
                  onChange={(e) =>
                    setContactForm({
                      ...contactForm,
                      last_name: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="address" className="formLabel">
                Address <span>*</span>
              </label>
              <input
                id="address"
                name="address"
                type="text"
                onChange={(e) =>
                  setContactForm({
                    ...contactForm,
                    address: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="city" className="formLabel">
                City <span>*</span>
              </label>
              <input
                id="city"
                name="city"
                type="text"
                onChange={(e) =>
                  setContactForm({
                    ...contactForm,
                    city: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="number" className="formLabel">
                Phone Number <span>*</span>
              </label>
              <input
                id="number"
                name="number"
                type="tel"
                placeholder="000-000-0000"
                pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}|[0-9]{10}"
                onChange={(e) =>
                  setContactForm({
                    ...contactForm,
                    number: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="email" className="formLabel">
                Email <span>*</span>
              </label>
              <input
                id="email"
                name="email"
                type="email"
                onChange={(e) =>
                  setContactForm({
                    ...contactForm,
                    email: e.target.value,
                  })
                }
                required
              />
            </div>
            <div className="flexContainer formSection">
              <label htmlFor="how_can_we_help" className="formLabel">
                How can we help? <span>*</span>
              </label>
              <textarea
                name="how_can_we_help"
                id="how_can_we_help"
                cols="30"
                rows="5"
                onChange={(e) =>
                  setContactForm({
                    ...contactForm,
                    how_can_we_help: e.target.value,
                  })
                }
                required
              ></textarea>
            </div>
            <button type="submit" className="submitButton ">
              Submit
            </button>
            <div
              style={{
                textIndent: -99999,
                whiteSpace: "nowrap",
                overflow: "hidden",
                position: "absolute",
              }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="_gotcha"
                tabIndex="-1"
                autoComplete="off"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
