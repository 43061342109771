import React from "react";

import Included from "./Included";
import ServiceText from "./ServiceText";

// image imports
import sidingWashingHeader from "../../../media/Siding Washing Header.png";

const SidingWashing = () => {
  return (
    <>
      <div className="servicesHeader">
        <img
          src={sidingWashingHeader}
          alt="Window Cleaning title over an image of a house"
        />
      </div>

      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Siding Washing</h1>

          <div className="windowContent">
            <p>
              All of our siding washing is done by hand using an eco-friendly
              cleaning solution specifically made for siding. We use soft
              bristle brushes that are strong enough to remove the toughest
              dirt, but soft enough that you could use them on a car.
            </p>
          </div>

          <div className="windowContent">
            <h2>The Benefits to Washing Your Siding</h2>
            <p>
              Over the course of a year, especially with our Canadian winter,
              you'll notice that siding gets pretty dirty, Siding tends to
              attract dirt, mildew, algae, moss, cobwebs, even salt. Cleaning
              your siding annually not only makes it look beautiful each year,
              it also prevents long-term damage that could result in permanent
              staining and a shorter lifetime for your siding.{" "}
            </p>
          </div>

          <ServiceText />
          <Included />
        </div>
      </div>
    </>
  );
};

export default SidingWashing;
