import React from "react";
import Included from "./Included";
import ServiceText from "./ServiceText";

// image imports
import eavestroughHeader from "../../../media/Eavestrough Cleaning Header.png";

const Eavestrough = () => {
  return (
    <>
      <div className="servicesHeader">
        <img
          src={eavestroughHeader}
          alt="Window Cleaning title over an image of a house"
        />
      </div>

      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Eavestrough Cleaning</h1>

          <div className="windowContent">
            <h2>The Benefits of Eavestrough Cleaning</h2>
            <p>
              Removing the debris from your eavestrough on a regular basis is
              one of the most important ongoing maintenance projects for your
              home. Eavestroughs are designed to keep your house safe from water
              damage and ensure that water gets funneled away from the
              foundation of your home to ensure it doesn't cause any damage to
              your home. Keeping up with regular eavestrough cleaning will help
              avoid overflowing water that can flood back into your home and
              cause damage.
            </p>

            <p>
              Also if left without cleaning for too long the leaves, debris and
              water can make for a great environment to attract mosquitos, and
              other insects and pests, all things that nobody wants around their
              home. Over time if left, the weight of debris and water can also
              lead to damage to the eavestroughs themselves leading to eaves
              that sag, become detached, and don't function as they should.
            </p>

            <p>
              Depending on the number of trees you have near your property we
              recommend cleaning out your eavestroughs one to two times annually
              ideally in the spring before the heavy rain and in the fall as the
              trees shed all their leaves.
            </p>
          </div>

          <ServiceText />
          <Included />
        </div>
      </div>
    </>
  );
};

export default Eavestrough;
