import React from "react";
import { Link } from "react-router-dom";

// Images
import greenRightGuarantee from "../../media/Green Right Guarantee PNG.png";
import vipRewardsImg from "../../media/VIP Rewards Join Now for Free Button.png";
import readersChoice from "../../media/Reader's Choice 2021.png";
import threeBestRated from "../../media/Three Best Rated.png";
import freeQuoteButton from "../../media/Get Your Free Quote Button.png";
import customerReviewImg from "../../media/Customers Are Saying.png";
import starRating from "../../media/4-8-on-5-rating.png";
import serviceProvideImg from "../../media/Learn More Abour Our Services.png";
import summerJob from "../../media/Now Hiring - Great Opporutnity.jpg";
import familyVipGroup from "../../media/Family Home Green Trees & Clothes.png";
import greenIsNotJustInOurName from "../../media/Green Is Not Just 2 PNG.png";
import greatQualityGuarantee from "../../media/Great Quality.....png";
import qualityBusiness from "../../media/Badge-2023-Green Right Window Cleaning Ltd.jpg";
import banner2023 from "../../media/Banner-2023-Green Right Window Cleaning Ltd.jpg";

export default function Home() {
  return (
    <div>
      <section className="homeSection1">
        <div className="wrapper">
          <h1 className="visuallyHidden">Green Right Window Cleaning</h1>
          <h2 className="homeH2">
            Serving Cambridge, Guelph, Kitchener, Waterloo, Etobicoke, &
            Mississauga
          </h2>
          <div className="greenRightGuarantee">
            <img
              src={greenRightGuarantee}
              alt="Our Green Right Guarantee: Great Quality, Great Price, Always Green!"
            />
          </div>
          <div className="vipRewardsImg">
            <Link to="/ourVipRewardsProgram">
              <img
                src={vipRewardsImg}
                alt="Click here to join our VIP rewards program for free"
              />
            </Link>
          </div>
        </div>
      </section>
      <div className="wrapper">
        <section className="homeAwardsSection">
          <div className="awardsDiv">
            <div className="readersChoice">
              <img
                src={readersChoice}
                alt="Cambridge Times Readers's Choice 2021 Diamond Winner"
              />
            </div>
            <div className="threeBestRated">
              <img
                src={threeBestRated}
                alt="Three Best Rated Certificate of Excellence Award"
              />
            </div>
          </div>
          <div className="awardsDiv">
            <div className="qualityImg">
              <img
                src={qualityBusiness}
                alt="2023 Winner of Quality Business Awards"
              />
            </div>
            <div className="bannerImg">
              <img src={banner2023} alt="2023 Best Window Cleaners Award" />
            </div>
          </div>
        </section>
        <hr />
        <section className="homeService container">
          <h3>
            We offer: exterior and interior window cleaning, siding cleaning,
            power washing, eavestrough cleaning, and mosquito & tick control.
          </h3>

          <Link to="/freeQuote">
            <img
              src={freeQuoteButton}
              alt="Click here to get your free quote today"
            />
          </Link>
        </section>
        <hr />
        <section className="container homeCustomerRating">
          <div className="homeCustomerRatingContainer">
            <Link to="/customerReviews" className="speechBubbleImg">
              <img
                src={customerReviewImg}
                alt="Click here to see what our customers are saying"
              />
            </Link>
            <div className="container starRatingContainer">
              <div className="starContainer">
                <img src={starRating} alt="4.8 star rating out of 5" />
              </div>
              <h5>4.8 out of 5 average rating </h5>
            </div>
          </div>
        </section>
        <hr />
        <section className="homeInfoContainer container">
          <ul className="homeInfo">
            <li>
              <Link to="/ourServices">
                <img
                  src={serviceProvideImg}
                  alt="Click here to learn more about the services we provide"
                />
              </Link>
            </li>
            <li>
              <Link to="/jobOpportunities">
                <img
                  src={summerJob}
                  alt="Click here to see summer job opportunities"
                />
              </Link>
            </li>
          </ul>
          <ul className="homeInfo homeInfo2">
            <li>
              <Link to="/ourVipRewardsProgram">
                <img
                  src={familyVipGroup}
                  alt="Click here to learn more about our VIP rewards program"
                />
              </Link>
            </li>
            <li>
              <Link to="/alwaysGreen">
                <img
                  src={greenIsNotJustInOurName}
                  alt="Click here to learn more about how we care for our environment and stay green"
                />
              </Link>
            </li>
          </ul>
        </section>
        <hr />
        <section className="container homeGuarantee">
          <Link to="/ourGuarantee">
            <img
              src={greatQualityGuarantee}
              alt="Click here to learn more about our Green Right guarantee"
            />
          </Link>
        </section>
      </div>
    </div>
  );
}
