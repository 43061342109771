import React from "react";
import { Link } from "react-router-dom";
import Included from "./Included";

// image imports
import serviceImage from "../../../media/Our Services Header.png";
import windowCleaningImage from "../../../media/Our Services - Window Cleaning.bmp";
import sidingWashingImage from "../../../media/Our Services - Siding Washing.bmp";
import eavesImage from "../../../media/Our Services - Eavestrough Cleaning.bmp";
import powerWashingImage from "../../../media/Our Services - Power Washing.bmp";
import mosquitoControlImage from "../../../media/Our Services - Mosquito & Tick Control.bmp";
import todayQuote from "../../../media/Get Your Free Quote Button.png";

const Services = () => {
  return (
    <>
      <div className="servicesHeader">
        <img src={serviceImage} alt="Our Services Header" />
      </div>
      <div className="serviceContent">
        <div className="wrapper">
          <h1 className="servicesTitle">Our Services</h1>
          <div className="work">
            <ul>
              <li>
                <Link to="/windowCleaning">
                  <img
                    src={windowCleaningImage}
                    className="serviceImage"
                    alt="Click here to learn more about our Window Cleaning service"
                  />
                </Link>
                <h2 className="serviceSubtitle">Window Cleaning</h2>
              </li>
              <li>
                <Link to="/sidingWashing">
                  <img
                    src={sidingWashingImage}
                    className="serviceImage"
                    alt="Click here to learn more about our Siding Washing service"
                  />
                </Link>
                <h2 className="serviceSubtitle">Siding Washing</h2>
              </li>
              <li>
                <Link to="/eavestrough">
                  <img
                    src={eavesImage}
                    className="serviceImage"
                    alt="Click here to learn more about our Eavestrough Cleaning service"
                  />
                </Link>
                <h2 className="serviceSubtitle">Eavestrough Cleaning</h2>
              </li>
              <li>
                <Link to="/powerWashing">
                  <img
                    src={powerWashingImage}
                    className="serviceImage"
                    alt="Click here to learn more about our Power Washing service"
                  />
                </Link>
                <h2 className="serviceSubtitle">Power Washing</h2>
              </li>
              <li>
                <Link to="/mosquitoControl">
                  <img
                    src={mosquitoControlImage}
                    className="serviceImage"
                    alt="Click here to learn more about our Mosquito and Tick control"
                  />
                </Link>
                <h2 className="serviceSubtitle">Mosquito & Tick Control</h2>
              </li>
            </ul>
          </div>

          <div className="serviceInfoDiv">
            <div className="serviceInfo">
              <p>
                At <span className="companyAccent">Green Right</span> Window
                Cleaning we take great pride in the services we provide. Whether
                we are cleaning your windows, washing your siding, cleaning out
                the debris in your eavstroughs, or power washing your driveway
                or deck, or safeguarding your backyard from mosquitoes and
                ticks, we put the greatest of care into the work we do on your
                home.
              </p>

              <p>
                Our professinally trained staff strives to complete every job,
                no matter what the size, as if they were doing the work on their
                own home.
              </p>

              <p>
                Our goals is to build long lasting relationships with our
                customers by providing quality service that will have you
                bringing us back year after year!
              </p>
            </div>
            <div className="quoteTodayButton">
              <Link to="/freeQuote">
                <img
                  src={todayQuote}
                  alt="Click here to get your free quote today"
                />
              </Link>
            </div>
          </div>

          <Included />
        </div>
      </div>
    </>
  );
};

export default Services;
