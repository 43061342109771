export default function ThankYou() {
  return (
    <>
      <div className="thankYou container">
        <div>
          <h1>Thank you!</h1>
        </div>
        <div>We'll be in touch soon.</div>
      </div>
    </>
  );
}
