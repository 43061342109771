import React from "react";

export default function Privacy() {
  return (
    <div className="wrapper container privacy">
      <h1 className="header">Green Right Window Cleaning Privacy Policy</h1>

      <p className="paragraph">
        This privacy policy has been compiled to better serve those who are
        concerned with how their 'Personally Identifiable Information' (PII) is
        being used online. PII is information that can be used on its own or
        with other information to identify, contact, or locate a single person,
        or to identify an individual in context. Please read our privacy policy
        carefully to get a clear understanding of how we collect, use, protect
        or otherwise handle your Personally Identifiable Information in
        accordance with our website.
      </p>

      <article>
        <h2>
          What personal information do we collect from the people that visit our
          blog, website, or app?
        </h2>
        <p className="paragraph">
          When booking a service, or filling out an estimate request, or a
          contact form on our site, as appropriate, you may be asked to enter
          your name, mailing address, email address, phone number, credit card
          information or other details needed to help you with your experience.
        </p>
      </article>

      <article>
        <h2>When do we collect information?</h2>
        <p className="paragraph">
          We only collect information from you when you submit a request to us,
          or when you book a service.
        </p>
      </article>

      <article>
        <h2>How do we use your information?</h2>
        <p className="paragraph">
          We may use the information we collect from you when you submit a
          request or book a service, or respond to a marketing communication, or
          use certain other site features in the following ways:
        </p>
        <ul className="dotList">
          <li>
            To administer a contest, promotion, survey or other site feature.
          </li>
          <li>To quickly process your transactions.</li>
          <li>
            To send emails regarding your order or other corresponding products
            and services.
          </li>
          <li>
            To follow up with you after correspondence (email or phone
            inquiries)
          </li>
        </ul>
      </article>

      <article>
        <h2>How do we protect your information?</h2>
        <p className="paragraph">
          Our website is scanned on a regular basis for security holes and known
          vulnerabilities in order to make your visit to our site as safe as
          possible. We use regular Malware Scanning.
        </p>
        <p className="paragraph">
          Your personal information is contained behind secured networks and is
          only accessible by a limited number of persons who have special access
          rights to such systems, and are required to keep the information
          confidential. In addition, all sensitive/credit information you supply
          is encrypted via Secure Socket Layer (SSL) technology.
        </p>
        <p className="paragraph">
          We implement a variety of security measures when a user places an
          order to maintain the safety of your personal information.
        </p>
        <p className="paragraph">
          All transactions are processed through a gateway provider and are not
          stored or processed on our servers.
        </p>
      </article>

      <article>
        <h2>Do we use 'cookies'?</h2>
        <p className="paragraph">
          We do not use cookies for tracking purposes.
        </p>
        <p className="paragraph">
          You can choose to have your computer warn you each time a cookie is
          being sent, or you can choose to turn off all cookies. You do this
          through your browser settings. Since browser is a little different,
          look at your browser's Help Menu to learn the correct way to modify
          your cookies.
        </p>
        <p className="paragraph">
          If you turn cookies off, some features will be disabled that make your
          site experience more efficient and may not function properly. However,
          you will still be able to book services.{" "}
        </p>
      </article>

      <article>
        <h2>Third-party disclosure</h2>
        <p className="paragraph">
          We do not sell, trade, or otherwise transfer to outside parties your
          Personally Identifiable Information unless we provide users with
          advance notice. This does not include website hosting partners and
          other parties who assist us in operating our website, conducting our
          business, or serving our users, so long as those parties agree to keep
          this information confidential. We may also release information when
          its release is appropriate to comply with the law, enforce our site
          policies, or protect ours or others' rights, property or safety.
        </p>
        <p className="paragraph">
          However, non-personally identifiable visitor information may be
          provided to other parties for marketing, advertising, or other uses.
        </p>
      </article>

      <article>
        <h2>Third-party links</h2>
        <p className="paragraph">
          The site may provide hypertext links to other website that may contain
          privacy provisions that are different than those provided herein. We
          are not responsible for the collection, use or disclosure of
          information collected through those websites, and we expressly
          disclaim any and all liability related to such collection, use or
          disclosure. You are encouraged to read the privacy policies of all
          other websites reached through use of the hypertext links from this
          site.{" "}
        </p>
      </article>

      <article>
        <h2>Google</h2>
        <p className="paragraph">
          Google's advertising requirements can be summed up by Google's
          Advertising Principles. They are put in place to provide a positive
          experience for users.{" "}
          <a href="http://support.google.com/adwordspolicy/answer/1316548?hl=en">
            https://support.google.com/adwordspolicy/answer/1316548?hl=en
          </a>{" "}
          We use Google AdSense Advertising on our website.
        </p>
        <p className="paragraph">
          Google, as a third-party vendor, uses cookies to serve ads on our
          site. Google's use of the DART cookie enables it to serve ads to our
          users based on previous visits to our site and other sites on the
          Internet. Users may opt-out of the use of the DART cookie by visiting
          the Google Ad and Content Network privacy policy.
        </p>
      </article>

      <article>
        <h2>We have implemented the following:</h2>
        <ul className="dotList">
          <li>Google Display Network Impression Reporting</li>
          <li>Demographics and Interests Reporting</li>
        </ul>
        <p className="paragraph">
          We, along with third-party vendors such as Google use first-party
          cookies (such as the Google Analytics cookies) and third-party cookies
          (such as the DoubleClick cookie) or other third-party identifiers
          together to compile data regarding user interactions with ad
          impressions and other ad service functions as they relate to our
          website.
        </p>
      </article>

      <article>
        <h2>Opting out:</h2>
        <p className="paragraph">
          Users can set preferences for how Google advertises to you using the
          Google Ad Settings page. Alternatively, you can opt out by visiting
          the Network Advertising Initiative Opt Out page or by using the Google
          Analytics Opt Out Browser add on.
        </p>
      </article>

      <article>
        <h2>How does our site handle Do Not Track Signals</h2>
        <p className="paragraph">
          We honor Do Not Track signals and Do Not Track, plant cookies, or use
          advertising when a Do Not Track (DNT) browser mechanism is in place.
        </p>
      </article>

      <article>
        <h2>Does our site allow third-party behavioral tracking?</h2>
        <p className="paragraph">
          It's also important to note that we do not allow third-party
          behavioral tracking.
        </p>
      </article>

      <article>
        <h2>COPPA (Children Online Privacy Protection Act)</h2>
        <p className="paragraph">
          When it comes to the collection of personal information from children
          under the age of 13 years old, the Children's Online Privacy
          Protection Act (COPPA) puts parents in control. The Federal Trade
          Commission, United States' consumer protection agency, enforces the
          COPPA Rule, which spells out what operators of websites and online
          services must do to protect children's privacy and safety online.
          Green Right Window Cleaning does not target children or provide
          products or services for use by children. We do not knowingly collect
          information from persons under the age of 18. If you are under the age
          of 18, you may review the site only with the involvement of a parent
          or guardian, and you may not use any portion of the site that would
          require collection of personal information from you.
        </p>
      </article>

      <article>
        <h2>CAN SPAM Act</h2>
        <p className="paragraph">
          The CAN-SPAM Act is a law that sets the rules for commercial email,
          establishes requirements for commercial messages, gives recipients the
          right to have emails stopped from being sent to them, and spells out
          tough penalties for violations. We collect your email address in order
          to:
        </p>
        <ul className="dotList">
          <li>
            Send information, respond to inquiries, and/or other requests or
            questions.
          </li>
          <li>
            Process orders and to send information and updates pertaining to
            orders.
          </li>
          <li>
            Send you additional information related to your product and/or
            service.
          </li>
          <li>
            Market to our mailing list or continue to send emails to our clients
            after the original transaction has occurred.
          </li>
        </ul>
      </article>

      <article>
        <h2>To be in accordance with CANSPAM, we agree to the following:</h2>
        <ul className="dotList">
          <li>Not use false or misleading subjects or email addresses</li>
          <li>
            Identify the message as an advertisement in some reasonable way.
          </li>
          <li>
            Include the physical address of our business or site headquarters.
          </li>
          <li>
            Monitor third-party email marketing services for compliance, if one
            is used.
          </li>
          <li>Honor opt-out/unsubscribe requests quickly.</li>
          <li>
            Allow users to unsubscribe by using the link at the bottom of each
            email.
          </li>
        </ul>
      </article>

      <article>
        <h2>Changes to your information:</h2>
        <p className="paragraph">
          You may change any of your information by calling us, emailing us, or
          using the contact us form on the site at any time.
        </p>
      </article>

      <p className="paragraph">
        If at any time you would like to unsubscribe from receiving future
        emails, you can email us at{" "}
        <a href="mailto:info@greenrightwindowcleaning.com">
          info@greenrightwindowcleaning.com
        </a>{" "}
        and we will promptly remove you from ALL correspondence.
      </p>

      <h2>Contacting Us</h2>
      <p className="paragraph">
        If there are any questions regarding this privacy policy, you may
        contact us using the information below.
      </p>
      <ul className="contactInfo">
        <li>
          <a href="http://www.greenrightwindowcleaning.com">
            www.greenrightwindowcleaning.com
          </a>
        </li>
        <li>Cambridge, Ontario</li>
        <li>Canada</li>
        <li>
          <a href="tel:+2263393998">226-339-3998</a>
        </li>
        <li>
          <a href="mailto:info@greenrightwindowcleaning.com">
            info@greenrightwindowcleaning.com
          </a>
        </li>
      </ul>
    </div>
  );
}
