import React from "react";
import { NavLink } from "react-router-dom";
import ReusableContactFooter from "../ReusableContactFooter";

// image imports
import earth from "../../../media/earth-in-palm_orig.jpg";
import waterRipple from "../../../media/water-drop-and-ripple_orig.jpg";
import growingPlant from "../../../media/plant-growing_orig.jpg";
import freeQuote from "../../../media/Get Your Free Quote Button.png";

const AlwaysGreen = () => {
  return (
    <>
      <div className="wrapper">
        <div className="alwaysGreen">
          <h1 className="titleGreen">Always Green!</h1>
        </div>

        <div className="alwaysGreenContent">
          <div className="alwaysGreenSubcontent">
            <div className="greenPhoto">
              <img src={earth} alt="Caring about the environment" />
            </div>
            <div className="greenContentWindow">
              <h2>It's In Our DNA</h2>
              <p>
                "Always Green" is not just something we say, and it's who we
                are! At Green Right Window Cleaning, we care about the
                environment and the world we live in. It's important to us that
                we act in a way that makes us proud of the world we'll be
                handing off to our children and our children's children.
              </p>
            </div>
          </div>

          <div className="alwaysGreenSubcontent">
            <div className="greenPhoto">
              <img src={waterRipple} alt="Green Right Guarateed Stamp" />
            </div>
            <div className="greenContentWindow">
              <h2>Pure Water</h2>
              <p>
                Our four stage water-purification unit ensures we are cleaning
                your windows with only the purest of water. Pure water
                evaporates spot free ensuring the cleanest of windows.
              </p>

              <p>
                In fact, the water is so pure you may catch us filling up our
                water bottles on a hot summer day!
              </p>
            </div>
          </div>

          <div className="alwaysGreenSubcontent">
            <div className="greenPhoto">
              <img
                src={growingPlant}
                alt="Learn more about our VIP rewards program"
              />
            </div>
            <div className="greenContentWindow">
              <h2>Eco-Friendly Cleaning Solutions</h2>
              <p>
                All solutions that we use for cleaning are eco-friendly,
                biodegradable, and will not harm the landscaping or plants
                around your home.
              </p>

              <p>
                We take great care when working around your home to mind your
                plants, flowers, and landscaping, as we aim to keep your
                property beautiful while making your windows sparkle!
              </p>
            </div>
          </div>

          <div className="freeQuote">
            <h3>It only takes a couple of minute to set up a quote!</h3>
            <NavLink to="/freeQuote" className="alwaysGreenQuoteButton">
              <img
                src={freeQuote}
                alt="Click here to get your free quote today!"
              />
            </NavLink>
          </div>

          <ReusableContactFooter />
        </div>
      </div>
    </>
  );
};

export default AlwaysGreen;
