import React from "react";
import { NavLink } from "react-router-dom";

import ReusableContactFooter from "../ReusableContactFooter";
// image imports
import vipRewards from "../../../media/VIP Rewards Join Now for Free Button.png";

const Included = () => {
  return (
    <>
      <div className="serviceInsurance">
        <h2>All Services Include:</h2>
        <ul>
          <li>
            <p>*$2M Liability Insurance</p>
          </li>
          <li>
            <p>*Full Worker's Compensation Coverage</p>
          </li>
          <li>
            <p>
              *Our <span className="companyAccent">Green Right</span> Guarantee:
              Great Quality, Great Price, Always Green!
            </p>
          </li>
        </ul>
        <div className="rewardsProgram">
          <div className="rewardsInfo">
            <p>
              Learn more about our VIP Rewards Program and the benefits of
              becoming a <span className="companyAccent">Green Right</span> VIP!
            </p>
          </div>
          <div className="vipProgram">
            <NavLink to="/ourVipRewardsProgram">
              <img
                src={vipRewards}
                alt="Click here to view our VIP rewards program"
              />
            </NavLink>
          </div>
        </div>
      </div>

      <ReusableContactFooter />
    </>
  );
};

export default Included;
